import styled from 'styled-components';
import PropTypes from 'prop-types';
import GatsbyImg from 'gatsby-image';

const Img = styled(GatsbyImg).attrs(p => ({
  imgStyle: { 
    objectFit: p.cover? 'cover' : 'contain', 
    objectPosition: p.position || "50% 50%", 
  },
}))`
  width: 100%;
  height: 100%;
`;
export default Img;

Img.propTypes = {
  cover: PropTypes.bool,
  position: PropTypes.string,
}