import styled from 'styled-components';
import {
  responsive_font,
} from '../../styles/_mixins';
import {
  colors,
} from '../../styles/_variables';

export default styled.p`
  line-height: 1.56;
  ${p => p.fz? responsive_font(p.fz) : responsive_font('body3')};
  color: ${props => props.color || 'inherit'};
  margin-bottom: ${props => props.noMargin? '0': '.8em'};
  font-weight: ${props => props.fw || 'normal'};
`;

/* 
fz
*/