import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import {createGlobalStyle} from 'styled-components';
import { Helmet } from "react-helmet"
import Header from "./Header"
import Footer from "./Footer"
import "./base.css"

const GlobalStyle = createGlobalStyle`
  :root, html, body {
    font-family: 'Open Sans', sans-serif;
  }
  * {
    font-family: 'Open Sans', sans-serif;
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          url
          description
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
      </Helmet>
      <Helmet>
        <meta property="og:site_name" content={data.site.siteMetadata.title}></meta>
        <meta property="og:title" content="Ek Aur Ek" />
        <meta name="og:image" content="/favicon.png" />
    </Helmet>
      <GlobalStyle />
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
