import {
    css
} from 'styled-components';
import {
    breakpoints,
    font_size,
    colors,
} from './_variables';

/* _scroll_snap_ */
/* _flex_row_ */
/* _flex_col_ */
/* _padding_margin_ */
/* _responsive_ */
/* _font_ */
/* _scroll_snap_ */
/* _misc_ */

export const text_highlight = css`
    *::selection {
        background-color: #101010; 
        color: #fff;
    }
    *::-moz-selection {
        background-color: #101010;
        color: #fff;
    }
`;
export const text_highlight_invert = css`
    &::selection {
        background-color: #fff; /* WebKit/Blink Browsers */
        color: #101010;
    }
    &::-moz-selection {
        background-color: #fff; /* Gecko Browsers */
        color: #101010;
    }
`;

/* _flex_row_ */
export const flex_center_center = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
export const flex_center_start = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
`;
export const flex_center_end = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
`;
export const flex_between_center = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
export const flex_around_start = css`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
`;
export const flex_even_center = css`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
`;
export const flex_around_center = css`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
`;
export const flex_around_end = css`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
`;
export const flex_between_start = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;
export const flex_start_start = css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
`;
export const flex_start_stretch = css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
`;
export const flex_start_center = css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;
export const flex_start_end = css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
`;
export const flex_end_start = css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
`;
export const flex_end_center = css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;
/* _flex_col_ */
export const flex_column_center_center = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
export const flex_column_stretch_center = css`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
`;
export const flex_column_center_start = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
`;
export const flex_column_start_center = css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;
export const flex_column_start_end = css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
`;
export const flex_column_between_start = css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`;
export const flex_column_around_start = css`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
`;
export const flex_column_around_end = css`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
`;
export const flex_column_between_end = css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
`;
export const flex_column_start_start = css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;
export const flex_column_end_start = css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
`;
export const responsive_font = (variable) => css`
    font-size: ${font_size[`${variable}_xs`]};
    @media screen and (min-width: ${breakpoints.sm}) {
        font-size: ${font_size[`${variable}_sm`]};
    }
    @media screen and (min-width: ${breakpoints.md}) {
        font-size: ${font_size[`${variable}_md`]};
    }
    @media screen and (min-width: ${breakpoints.lg}) {
        font-size: ${font_size[`${variable}_lg`]};
    }
    @media screen and (min-width: ${breakpoints.xl}) {
        font-size: ${font_size[`${variable}`]};
    }
`;
/* _padding_margin_ */
export const padding_x = (padding) => (css`
    padding-left: ${padding};
    padding-right: ${padding};
`);
export const padding_y = (padding) => (css`
    padding-top: ${padding};
    padding-bottom: ${padding};
`);
export const margin_x = (margin) => (css`
    margin-left: ${margin};
    margin-right: ${margin};
`);
export const margin_y = (margin) => (css`
    margin-top: ${margin};
    margin-bottom: ${margin};
`);

/* _responsive_ */
export const respondTo = () => {
    Object.keys(breakpoints).reduce((accumulator, label) => {
        accumulator[label] = (...args) => css`
            @media (min-width: ${breakpoints[label]}) {
                ${css(...args)};
            }
        `;
        return accumulator;
    }, {});
}
/* --usage-- 
    ${respondTo.sm`
		background-color: aqua;
	`}
*/
export const desktopRespondTo = () => {
    Object.keys(breakpoints).reduce((accumulator, label) => {
        accumulator[label] = (...args) => css`
            @media (max-width: ${breakpoints[label]}) {
                ${css(...args)};
            }
        `;
        return accumulator;
    }, {});
}
export const reducedMotion = css`
    @media (prefers-reduced-motion: reduce) {
        & {
            transition: none;
        }
    }
`;

/* _font_ */
export const rem = (size = 1) => `${size * 0.610}rem`;
export const font_family = css`
    font-family: 'Open Sans', sans-serif;
`;
/* _scroll_snap_ */
export const scroll_snap_container_x = css`
    overflow-x: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
`;
export const scroll_snap_slide_x = css`
    scroll-snap-align: start;
`;
export const scroll_snap_container_y = css`
    overflow-x: auto;
    overscroll-behavior-y: contain;
    scroll-snap-type: y mandatory;
`;
export const scroll_snap_slide_y = css`
    scroll-snap-align: center;
`;

/* _misc_ */
export const hide_scrollbar = css`
    & { 
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
    }
    &::-webkit-scrollbar { width: 0 !important; display: none; }
`;
export const custom_scrollbar = css`
    &::-webkit-scrollbar {
        width: 2px;
        height: 4px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background: hsl(44, 30%, 60%);
        border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: hsl(44, 50%, 50%);
    }
`;
export const hide_number_input_spin = css`
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; 
    }
`;
export const no_touch_highlight = css`
    -webkit-tap-highlight-color: transparent;
`;
export const test = (color = 'red') => css`
    border: 1px solid ${color};
`;