import styled from 'styled-components';

import {breakpoints,} from '../../styles/_variables';

export default styled.div`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-height: 100%;
    height: 100%;
    /* position: relative; */
    @media (min-width: ${breakpoints.sm}) { /* sm */
        max-width: ${breakpoints.sm_width};
    }
    @media (min-width: ${breakpoints.md}) { /* md */
        max-width: ${breakpoints.md_width};
    }
    @media (min-width: ${breakpoints.lg}) { /* lg */
        max-width: ${breakpoints.lg_width};
    }
    @media (min-width: ${breakpoints.xl}) { /* xl */
        max-width: ${breakpoints.xl_width};
    }
    @media (min-width: ${breakpoints.xx}) { /* xl */
        max-width: ${breakpoints.xx_width};
    }
`;

