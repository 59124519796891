import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";
import styled from 'styled-components';
import Container from './layout/Container';
const logoImg = require("../assets/images/logo.png");
import Hamburger from './elements/Hamburger';
import {
  colors,
  breakpoints,
} from '../styles/_variables';

const HeaderC = styled.header.attrs({
  className: `absolute z-10 w-full`,
})`
  top: 0;
  background-color: ${colors.bg_white};
`;
const Content = styled.header.attrs({
  className: `flex justify-between items-center py-3`
})`
`;
const HamburgerW = styled.div.attrs({
  className: `md:hidden`
})`
  width: 1.8rem;
  height: 1.2rem;
`;
const LogoW = styled.a.attrs({
  href: "/",
  className: ``,
})`
  width: 13rem;
  height: 3rem;
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 0% 50%;
`;
const Nav = styled.nav.attrs({
  className: `m-0 hidden md:block`
})`
  @media screen and (min-width: ${breakpoints.md}) {
    position: relative;
  }
`;
const NavMobile = styled.nav.attrs({
  className: `nav-mobile m-0 md:hidden`
})`
  height: ${p => p.isClicked? '9em': '0'};
  transition: height 0.25s ease;
  & .nav-items {
    opacity: ${p => p.isClicked? '1': '0'};
    transform: ${p => p.isClicked? 'translateY(0)': 'translateY(-1em)'};
    transition: opacity 0.15s ease, transform 0.15s ease;
  }
`;
const NavItems = styled.ul.attrs({
  className: `nav-items
    m-0 flex flex-col py-8 md:py-0 md:py-0 md:flex-row`
})`
  transition: opacity 0.25s ease, transform 0.15s ease 1s;
`;
const NavItem = styled.li.attrs({
  className: `m-0 list-none mr-16 mb-6 py-1 px-4 md:mb-0 md:mt-0
    rounded-sm uppercase`
})`
  transition: background-color 0.25s ease-in,
              color 0.25s ease-out;
  transition-duration: 0.15s;
  width: 100%;
  @media screen and (min-width: ${breakpoints.md}) {
    width: auto;
    &:hover {
      transition-duration: 0.25s;
      background-color: ${colors.primary};
      color: ${colors.text_white};
    }
  }
  &:last-child {
    margin-bottom: 1em;
    @media screen and (min-width: ${breakpoints.md}) {
      margin-bottom: 0;
    }
  }
  & a {
    font-weight: 600;
    margin: 0;
    width: 100%;
    display: block;
    @media screen and (min-width: ${breakpoints.md}) {
      display: inline-block;
      width: auto;
      font-weight: normal;
    }
  }
  &:last-child {
    margin-right: 0;
  }
`;

const renderNavItems = () => (
  <NavItems>
    <NavItem>
      <a href="/about">About</a>
    </NavItem>
    <NavItem>
      <a href="/donate">Donate</a>
    </NavItem>
  </NavItems>
);

const Header = ({ siteTitle }) => {
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const handleMenuClick = () => {
    setIsMenuClicked(!isMenuClicked);
  }
  // useEffect(() => {
  //   const nav = document.querySelector('.nav-mobile');
  //   // console.log(isMenuClicked);
  //   if(isMenuClicked) {
  //     nav.style.visibility = 'visible';
  //   } else {
  //     setTimeout(() => {
  //       nav.style.visibility = 'hidden';
  //     }, 250);
  //   }
  // }, [isMenuClicked])
  return (
    <HeaderC>
      <Container>
        <Content>
          <LogoW>
            <Img src={logoImg} alt="Ek Aur Ek Gyara"/>
          </LogoW>
          <Nav>
            {renderNavItems()}
          </Nav>
          <HamburgerW onClick={handleMenuClick}>
            <Hamburger
              isClicked={isMenuClicked}
              color={colors.primary}
            />
          </HamburgerW>
        </Content>
      </Container>
  
      <NavMobile isClicked={isMenuClicked}>
        <Container>
          {renderNavItems()}
        </Container>
      </NavMobile>
    </HeaderC>
  )
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
