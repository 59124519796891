import React from 'react';
import propTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  colors,
} from '../../styles/_variables';
import {
  headerT
} from '../../styles/timing';
const HamburderDiv = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    transition: margin-top .25s ease-out background-color ${headerT.hamburger} ease-out;
    margin-top: ${(props) => (props.isClicked ? '25%' : '0')};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .hamburger-stripe{
        position: ${(props) => (props.isClicked ? 'absolute' : 'relative')};
        background-color: ${(props) => (props.isClicked ? props.activeColor : props.color)};
    }
    .hamburger-stripe--top{
        transform: ${(props) => (props.isClicked ? 'rotate(45deg)' : 'rotate(0deg)')};
        margin-top: ${(props) => (props.isClicked ? '3px' : '0')};
    }
    .hamburger-stripe--middle{
        display: ${(props) => (props.isClicked ? 'none' : 'block')};
    }
    .hamburger-stripe--bottom{
        transform: ${(props) => (props.isClicked ? 'rotate(-45deg)' : 'rotate(0deg)')};
        margin-bottom: ${(props) => (props.isClicked ? '3px' : '0')};
    }
`;
const hamburderStripeStyle = css`
    width: 100%;
    height: 3px;
    display: block;
    transition: transform .25s ease-out, 
                margin-left .25s ease-out,
                background-color .25s ease-out;
`;
const HamburderStripeTop = styled.div.attrs({
  className: 'hamburger-stripe--top hamburger-stripe',
})`
    ${hamburderStripeStyle};
`;
const HamburderStripeBottom = styled.div.attrs({
  className: 'hamburger-stripe--bottom hamburger-stripe',
})`
    ${hamburderStripeStyle};
    margin-top: 3px;

`;
const HamburderStripeMiddle = styled.div.attrs({
  className: 'hamburger-stripe--middle hamburger-stripe',
})`
    ${hamburderStripeStyle};
    margin-top: 3px;
`;

export default function Hamburger({
  color,
  isTwoStripes,
  isClicked,
  activeColor,
}) {
  return (
    <HamburderDiv
      color={color}
      isClicked={isClicked}
      activeColor={activeColor}
    >
      <HamburderStripeTop />
      {isTwoStripes ? null : <HamburderStripeMiddle />}
      <HamburderStripeBottom />
    </HamburderDiv>
  );
}
Hamburger.defaultProps = {
  color: colors.primary,
  activeColor: colors.primary,
  isTwoStripes: false,
  // isClicked: false,
};
Hamburger.propTypes = {
  color: propTypes.string,
  isTwoStripes: propTypes.bool,
  isClicked: propTypes.bool.isRequired,
  activeColor: propTypes.string,
};
