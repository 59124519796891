import React from "react";
import styled, {StyledFunction} from "styled-components";
import { responsive_font, text_highlight_invert } from "../../styles/_mixins";
import { colors, breakpoints } from "../../styles/_variables";
import { font_family } from "../../styles/_mixins";

const H1 = styled.h1`
  font-weight: bold;
  color: ${(p) => (p.white && colors.text_white) || p.color || 'inherit'};
  position: relative;
  padding-bottom: 1em;
  line-height: 1.5em;
  ${responsive_font("h1")};
  ${font_family};
  & span {
    display: block;
  }
  &::after {
    content: "";
    display: block;
    width: 10rem;
    height: 0.18em;
    border-radius: 0.1em;
    background-color: ${(p) => (p.white && colors.text_white) || p.color || colors.text_black};
    position: absolute;
    bottom: 0em;
    left: 0;
  }
`
export default H1;