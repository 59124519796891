import React from 'react'
import styled from 'styled-components';
import {
  colors,
  font_size,
} from '../../styles/_variables';
import {
  responsive_font,
  flex_start_center,
} from '../../styles/_mixins';

const ButtonC = styled.button.attrs(p => ({
  as: p.href ?  'a' : 'button',
  className: `cursor-pointer
    uppercase font-semibold text-center 
    rounded-md ${p.left && 'rounded-r-none'} ${p.right && 'rounded-l-none'}
    ${p.full && 'w-full'} ${p.long ? 'px-10 xs:px-16 sm:px-20 lg:px-24' : 'px-8'} ${p.border ? 'py-2' : 'py-3'}`,
}))`
  display: inline-block;
  letter-spacing: 2.22px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  background-color: ${props => (props.border && colors.bg_white) 
                    || (props.inactive && colors.bg_white) 
                    || colors.primary};
  color: ${props => (props.border && colors.primary)
        || (props.inactive && colors.text_black)
        || colors.text_white};
  border: ${p => p.border? `3px solid ${colors.primary}` : "none"};
  outline: none;
  cursor: ${p => p.disabled? 'not-allowed' : "pointer"};
  font-size: inherit;
  transition: background-color 0.25s ease-in,
              color 0.25s ease-out;
  transition-duration: 0.15s;
  &:hover {
    transition-duration: 0.25s;
    background-color: ${props => (props.border && colors.primary)
                    || (props.inactive && colors.primary_light) 
                    || colors.primary_light};
    color: ${props => (props.border && colors.text_white)
        || (props.inactive && colors.text_white)
        || colors.text_white};
  }
`;
export default function Button(props) {
  if(props.to) {
    return (
      <a href={props.to} style={{
        width: 'auto',
      }} target={props.target}>
      <ButtonC {...props} />
      </a>
    )
  }
  return (
    <>
      <ButtonC {...props} />
    </>
  )
}
Button.defaultProps = {
  color: colors.white,
  inactive: false,
  right: false,
  left: false,
  secondary: false,
  primary: false,
  border: false,
  long: false,
  full: false,
}