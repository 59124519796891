import { rem } from "./_mixins";
/*
    _LAYOUT_
    _COLORS_
    _FONT_SIZE_
*/

/* _LAYOUT_ */
export const fontsize = {
  form_label: "2rem",
};

const layout_width = {
  sm: 534,
  md: 720,
  lg: 960,
  xl: 1150,
  xx: 1300,
  fh: 1600,
};

export const breakpoints = {
  xs: '319.98px',
  sm: '575.98px',
  md: '767.98px',
  lg: '991.98px',
  xl: '1199.98px',
  xx: '1399.98px',
  fh: '1919.98px', /* full-hd (1920px) */
  zo: '2900px',
  sm_width: `${layout_width.sm}px`,
  md_width: `${layout_width.md}px`,
  lg_width: `${layout_width.lg}px`,
  xl_width: `${layout_width.xl}px`,
  xx_width: `${layout_width.xx}px`,
  fh_width: `${layout_width.fh}px`,

  sm_width_half: `${layout_width.sm / 2}px`,
  md_width_half: `${layout_width.md / 2}px`,
  lg_width_half: `${layout_width.lg / 2}px`,
  xl_width_half: `${layout_width.xl / 2}px`,
  xx_width_half: `${layout_width.xx / 2}px`,
};

/* _COLORS_ */
export const colors = {
  logo: "#FF00B7",
  primary: "#05754c",
  primary_light: "hsl(158, 70%, 40%)",
  primary_dark: "hsl(160, 80%, 20%)",
  secondary: "#323233",

  text_black: "#081f1a",
  text_black_thin: "#00201a",
  text_gray: "#647d90",
  text_light_gray: "#a7a7a7",
  text_white: "#fff",
  text_highlight: "#fff",
  text_white_shade: "#dedede",

  secondary_hover: "#FFF5FE",

  white: "#fff",
  white_shade_dark: "#fafafa",
  white_shade_light: "#fbfbfb",
  white_accent: "#d8d8d8",
  black: "#000",
  gray: "#878787",
  gray_light: "#f2f2f2",

  bg_primary: "#dbe8b9",
  bg_primary_light_accent: "#e8f2ee",
  bg_secondary: "#ffe08e",
  bg_secondary_dark: "hsl(44, 30%, 60%)",
  bg_white: "#fff",

  focus_bg: "#fff",
  btn_hover: "#62C6FF",
  danger: "#F64040",
  danger_hover: "tomato",
  none: "rgba(0, 0, 0, 0)",
  label: "#828283",

  dark: {

  },
};
/* _FONT_SIZE_ */
export const font_size = {
  button: rem(1.4),
  button_sm: rem(1),
  button_md: rem(1.4),
  button_lg: rem(2),

  h1: rem(4.3),
  h1_lg: rem(4.1),
  h1_md: rem(4.0),
  h1_sm: rem(3.7),
  h1_xs: rem(3.4),

  section_title: rem(1.95),
  section_title_lg: rem(1.95),
  section_title_md: rem(1.9),
  section_title_sm: rem(1.8),
  section_title_xs: rem(1.8),

  section_header: rem(3.3),
  section_header_lg: rem(3.3),
  section_header_md: rem(3.3),
  section_header_sm: rem(3.2),
  section_header_xs: rem(3.2),

  heading: rem(3.4),
  heading_lg: rem(3.4),
  heading_md: rem(3.4),
  heading_sm: rem(3.3),
  heading_xs: rem(3.2),

  body: rem(2.6),
  body_lg: rem(2.6),
  body_md: rem(2.5),
  body_sm: rem(2.4),
  body_xs: rem(2.2),

  body2: rem(2.4),
  body2_lg: rem(2.3),
  body2_md: rem(2.2),
  body2_sm: rem(2.1),
  body2_xs: rem(2.0),

  body3: rem(1.9),
  body3_lg: rem(1.9),
  body3_md: rem(1.8),
  body3_sm: rem(1.7),
  body3_xs: rem(1.6),

  sub_title: rem(3.6),
  sub_title_lg: rem(3.2),
  sub_title_md: rem(2.7),
  sub_title_sm: rem(2.2),
  sub_title_xs: rem(2.1),

  small: rem(1.3),
  small_lg: rem(1.3),
  small_md: rem(1.3),
  small_sm: rem(1.2),
  small_xs: rem(1.2),

  huge: rem(5.6),
  huge_lg: rem(5.5),
  huge_md: rem(5.5),
  huge_sm: rem(5.5),
  huge_xs: rem(5.5),
};
export const layout = {
  section_padding_y: "2rem",
  section_padding_mobile_y: "1.8rem",

  left_flex_section_width: 45,
  left_flex_section_width_md: 40,
};

export const centerPaddingSizes = {
  xs: "20px",
  sm: "40px",
  md: "60px",
  lg: "75px",
  xl: "100px",
};
