import styled from 'styled-components';
import {
  responsive_font,
} from '../../styles/_mixins';
import {
  colors,
} from '../../styles/_variables';

export default styled.p`
  ${responsive_font('heading')};
  color: ${props => props.color || 'inherit'};
  font-weight: ${props => props.fw || 'normal'};
  line-height: ${props => props.lh || 1.625};
  margin-bottom: ${props => props.noMargin? '0' : '0.6em'};
`;