import React from "react";
import styled from "styled-components";
import { responsive_font, } from "../../styles/_mixins";
import { colors, breakpoints } from "../../styles/_variables";
import { font_family } from "../../styles/_mixins";

export default styled.h2`
  font-weight: bold;
  color: ${p => p.color || 'inherit'};
  position: relative;
  margin-top: 1em;
  margin-bottom: .5em;
  line-height: 1.38em;
  ${responsive_font("section_header")};
  ${font_family};
`
