import styled from "styled-components"
import { responsive_font } from "../../styles/_mixins"
import { colors } from "../../styles/_variables"

export default styled.h2`
  font-weight: 800;
  text-transform: uppercase;
  padding-bottom: 1em;
  color: ${props => (props.dark ? colors.text_light_gray : colors.text_black)};
  ${responsive_font("section_title")};
`
