import React, {useRef} from 'react'
import styled from 'styled-components';
import Container from './layout/Container';
import {P3} from './elements';
import {
  colors,
  breakpoints,
} from '../styles/_variables';
const footerLogo = require('../assets/images/logo.png');
import serviceData from '../../content/service.yaml';

const Section = styled.section.attrs({
  className: `py-4`
})`
`;
const A = styled(P3).attrs({
  as: 'a',
  className: ``
})`
  display: block;
`;
const Img = styled.div.attrs({
  className: `w-48 h-16 md:w-48 bg-contain bg-center`
})`
  background-image: url(${footerLogo});
  background-repeat: no-repeat;
  border: none;
`;
const Content = styled.div.attrs({
  className: `
    flex flex-col items-center 
    md:flex-row md:justify-between`
})`
`;
const PhEmail = styled.div.attrs({
  className: `flex items-center mt-4`
})`
  & a:first-child {
    margin-right: 2em;
  }
`;
const Footer: React.FC = () => {
  return (
    <Section bg={colors.bg_white}>
      <Container>
        <Content>
          <Img />
          <PhEmail>
            <A href={`mailto:${serviceData.contact.email}`}
            color={colors.primary}>
              {serviceData.contact.email}
            </A>
            <A href={`tel:${serviceData.contact.ph1}`}
            color={colors.primary}>
              {serviceData.contact.ph1}
            </A>
          </PhEmail>
        </Content>
      </Container>
    </Section>
  )
}

export default Footer;
