import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  responsive_font,
} from '../../styles/_mixins';
import {
  colors,
} from '../../styles/_variables';

const P2 =  styled.p`
  line-height: 1.56;
  color: ${props => props.color || 'inherit'};
  font-weight: ${props => props.fw || 'normal'};
  margin-bottom: ${props => props.noMargin? '0': '0.6em'};
  ${responsive_font('body2')};
`;
export default P2;

P2.propTypes = {
  fw: PropTypes.oneOf([PropTypes.string, PropTypes.number])
}
