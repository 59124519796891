import React from "react";
import styled from "styled-components";
import { responsive_font, text_highlight_invert } from "../../styles/_mixins";
import { colors, breakpoints } from "../../styles/_variables";

export default styled.h2`
  font-weight: bold;
  text-transform: uppercase;
  color: ${p => p.color || 'inherit'};
  position: relative;
  letter-spacing: 2.28px;
  ${responsive_font("section_title")};
  & span {
    display: block;
  }
  @media screen and (min-width: ${breakpoints.md}) {
  }

  &::after {
    content: "";
    display: block;
    width: 8rem;
    height: 0.2em;
    border-radius: 3px;
    background-color: ${p => p.lineColor || colors.primary};
    position: absolute;
    bottom: -1em;
    left: 0;
  }
`;
