import React from 'react'
import styled from 'styled-components';
import {
  colors,
} from '../../styles/_variables';

const Wrapper = styled.div.attrs({
  className: `flex items-center`
})`
`;
const Label = styled.span.attrs({
  className: `inline-block mr-3`
})`
  font-weight: ${p => p.fw || 'normal'};
  color: ${colors.primary};
  font-size: 1.5em;
`;
const InputC = styled.input.attrs({
  className: `rounded-md px-3 py-2`
})`
  border: 2px solid ${colors.primary};
  width: 100%;
  outline: none;
  font-weight: ${p => p.fw || 'normal'};
  &:focus,
  &:hover {
    border: 2px solid ${colors.primary_light};
  }
`;

function Input(props) {
  const {label, fw} = props;
  return (
    <Wrapper>
      {label && 
        <Label fw={fw}>{label}</Label>
      }
      <InputC {...props} />
    </Wrapper>
  )
}


export default Input;

