  
import styled, { keyframes } from 'styled-components';

const loading = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const RotateSpin = styled.div`
  animation: ${loading} 1s infinite linear;
  border: 0.15em solid ${p => p.color};
  border-right: 0.15em solid transparent;
  border-radius: 50%;
  height: 1em;
  width: 1em;
  text-indent: -9999em;
  transform: translateZ(0);
  display: ${p => p.loading? 'inline-block' : 'none'};
`;

export default RotateSpin;

RotateSpin.defaultProps = {
  loading: false,
  color: 'yellow',
}