import styled, {css} from 'styled-components';
import {
  colors,
} from '../../styles/_variables';
export default styled.a.attrs(props => ({
  target: props.external? '_blank': '_self',
  noopener: props.external? 'true': 'false',
}))`
  text-decoration: none;
  position: relative;
  padding-bottom: 0.2em;
  font-size: inherit;
  color: inherit;
  cursor: ${p => p.isLink? 'pointer' : 'not-allowed'};
  font-weight: 600;
  &:hover::after {
    height: 2px;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    transition: height 0.3s ease-out;
    background-color: ${props => props.color};
  }
`;
