import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import {
  colors,
} from '../../styles/_variables';
import {
  rem,
} from '../../styles/_mixins';

const LinkC = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  padding-bottom: 0;
  cursor: pointer;
  will-change: transform, padding-bottom, color;
  color: ${props => props.isMenuClicked? colors.text_white : colors.text_black};
  outline: none;
  font-weight: 800;
  font-size: ${rem(1.5)};
  letter-spacing: 2px;
  transition: padding-bottom .15s linear, color .25s linear;
  &::after {
    content: "";
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${props => props.isMenuClicked? colors.text_white : colors.text_black};
    transform: scale(0, 1);
    transition: transform .25s linear 0.1s;
    transform-origin: right;
  }
  &:hover::after,
  &:focus::after{
    transform: scale(1, 1);
    width: 100%;
    transform-origin: left;
  }
  &:hover,
  &:focus{
    padding-bottom: 0.2em;
  }
`;

export default LinkC;
